.cast-item {
  position: relative;
  height: 100%;
  /* width: calc(100% / 8); */
  background: var(--highlight-color);
  border-radius: 10px;
  overflow: hidden;
}

.cast-item > img {
  height: 100%;
  width: 100%;
  mask-image: linear-gradient(black 45%, transparent);
}

.cast-item > .wrapper {
  position: absolute;
  bottom: 15px;
  left: 15px;
}
