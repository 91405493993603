.movie-details {
  height: 100vh;
  position: relative;
}

.movie-details > .backdrop {
  height: 100%;
  width: 100%;
  mask-image: linear-gradient(transparent, black, transparent);
  overflow: hidden;
}

.movie-details > .backdrop > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.movie-details > .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
}

.movie-details > .wrapper > .left {
  width: 80%;
  height: 100%;
  display: flex;
  flex-flow: column;
  float: left;
  box-sizing: border-box;
  padding: 142px 42px 42px 42px;
  row-gap: 42px;
}

.movie-details > .wrapper > .left > .upper {
  height: 70%;
  display: flex;
  float: inline-start;
}

.movie-details > .wrapper > .left > .upper > img {
  border-radius: 10px;
  height: 100%;
  box-shadow: 0 0 20px 5px var(--primary-color);
}

.movie-details > .wrapper > .left > .upper > .movie-info {
  display: flex;
  flex-flow: column;
  margin-left: 25px;
}

.movie-details > .wrapper > .left > .upper > .details > .actions {
  display: flex;
  column-gap: 10px;
}

.movie-details > .wrapper > .left > .lower {
  display: flex;
  column-gap: 25px;
  height: 30%;
  float: inline-start;
}

.movie-details > .wrapper > .right {
  width: 20%;
  height: 100%;
  float: right;
}

.movie-details > .wrapper > .right > .upper {
  height: 80%;
  float: inline-start;
}
