.movie-carousel {
  position: relative;
  height: 80vh;
  text-align: start;
}

.movie-carousel > .slider-backdrop {
  height: 100%;
  mask-image: linear-gradient(transparent, black, transparent);
  overflow: hidden;
}

.movie-carousel > .slider-backdrop img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.movie-carousel > .content-wrapper {
  position: absolute;
  bottom: 0;
  height: calc(100% - 100px);
  width: 100%;
}

.movie-carousel > .content-wrapper > .left {
  display: flex;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  float: left;
  padding-right: 20px;
}

.movie-carousel > .content-wrapper > .left > .trailer {
  height: 50%;
  width: 70%;
  border-radius: 10px;
  box-shadow: 0 0 20px 5px var(--primary-color);
  overflow: hidden;
}

.movie-carousel > .content-wrapper > .left > .trailer iframe {
  height: 100%;
  width: 100%;
  margin: 0;
}

.movie-carousel > .content-wrapper > .right {
  display: flex;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  float: right;
  padding-left: 20px;
}

.movie-carousel > .content-wrapper > .right > .wrapper {
  height: 50%;
  width: 70%;
  box-sizing: border-box;
}
