:root {
  --primary-color: #1c2028;
  --accent-color: #0ce8ce;
  --highlight-color: #2c3039;
}

body {
  background: var(--primary-color);
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(var(--primary-color), var(--accent-color));
  border-radius: 5px;
}
